<template>
  <div>
    <div class="search">
      <div class="title">指南列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="标题:">
          <el-input v-model="params.title"></el-input>
        </el-form-item>

      
        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
          <el-button type="primary" @click="$router.push('/addFinger')">添加</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column label="排序" width="120"> 
        <template slot-scope="{row}">
          <el-input v-model="row.dept" placeholder="请输入排序值" @change="setSort(row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" align="center"> </el-table-column>
      <el-table-column label="展示图 " width="120" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 70px; height: 70px"
            :src="scope.row.showImg"
            :preview-src-list="[scope.row.showImg]"
          >
          </el-image>
        </template>
      </el-table-column>

      <el-table-column prop="addTime" label="注册时间" width="200" align="center">
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
        
            <el-button type="primary"  size="mini" @click="remove(scope.row)">删除</el-button>
            <el-button type="primary"  size="mini" @click="$router.push('/addFinger?id='+scope.row.manualId)">编辑</el-button>
          
        </template>
        </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"

      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="分类名称:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
        <el-form-item label="分类图片:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {fingerpostList, fingerpostListSort,fingerpostListDel } from '../../../api/fingerpost.js'
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        title: "",

      },
      addList: {
        cateId: 0,
        cateName: "",
        cateImg: "",
      },
      addRules: {
        tableData: [
          { required: true, message: "请输入活动名称", trigger: ["blur", "change"] },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.getList()
  },
  methods: {
    rowKey(row) {
      return row.userId;
    },
    handleSelectionChange(val) {
    //   this.multipleSelection = val;
    },
    async getList() {
    //   this.params.beginTime = this.date ? this.date[0] : "";
    //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await fingerpostList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
    
      this.date = null;
      this.getList();
    },
    setSort(row) {
        let params = {
            manualId: row.manualId,
            dept:row.dept
        }
        fingerpostListSort(params).then(res => {
            if (res.data.code == 0) {
                this.$message.success("修改成功");
                this.getList();
            } else {
                this.$message.error(res.data.msg);
            }
        });
    },
    remove(row) {
        this.$confirm("此操作将删除该指南, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(() => {
                fingerpostListDel({manualId:row.manualId}).then(res => {
                    if (res.data.code == 0) {
                        this.$message.success("删除成功");
                        this.getList();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            })
            .catch(() => {
               
            });
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
