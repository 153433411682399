import request from '../utils/request'
import baseUrl from './baseUrl'
export const fingerpostList = (data) => request({
  url: baseUrl + '/manual/selectForBack',
  method: 'POST',
  data
})
export const fingerpostListAdd = (data) => request({
  url: baseUrl + '/manual/add',
  method: 'POST',
  data
})
export const fingerpostListDetails = (data) => request({
  url: baseUrl + '/manual/selectForId',
  method: 'POST',
  data
})
export const fingerpostListSort = (data) => request({
  url: baseUrl + '/manual/modify',
  method: 'POST',
  data
})
export const fingerpostListDel = (data) => request({
  url: baseUrl + '/manual/deleteById',
  method: 'POST',
  data
})